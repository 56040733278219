import { Navigate } from "react-router-dom";

import { Layout } from "antd";
import Sidebar from "../../containers/sidebar/Sidebar";

const { Content } = Layout;

function PrivateRoute({ path, isAuthenticated, children }) {

  let currentLink = window.location.pathname;

  return isAuthenticated ? (
    <Layout style={{ flexDirection: "row", overflowX: "hidden" }}>
      {/* {!isMobileView && <Sidebar url={location.pathname} />} */}
      <Layout>
        <Sidebar currentLink={currentLink} />
        <Content>{children}</Content>
      </Layout>
    </Layout>
  ) : (
    <Navigate
      to={{
        pathname: "/",
        state: { from: path },
      }}
    />
  );
}

export default PrivateRoute;

import Request from "./Request";

import app_config from "../config/app_config";

class Authentication {
  constructor() {
    this.Request = new Request();
  }

  login(credentials) {
    return new Promise((resolve, reject) => {
      // Create the payload containing user credentials
      const payload = {
        loginId: credentials.email,
        password: credentials.password,
        applicationId: app_config.appId,
      };

      // Call request to backend for login
      this.Request.post("/auth/login", payload)
        .then((res) => {
          let result = res.data;

          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  checkSession() {
    return new Promise((resolve, reject) => {
      // Call request to backend for login
      this.Request.get("/auth/login")
        .then((res) => {
          let result = res.data;

          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  logout() {
    return new Promise((resolve, reject) => {

      // Call request to backend for login
      this.Request.post("/auth/logout")
        .then((res) => {
          let result = res.data;

          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  updatePassword(userDetails) {
    return new Promise((resolve, reject) => {

      const payload = {
        loginId: userDetails.email,
        new_password: userDetails.new_password,
        old_password: userDetails.old_password,
        applicationId: app_config.appId,
      };

      // Call request to backend for update-password
      this.Request.post("/auth/update-password", payload)
        .then((res) => {
          let result = res.data;

          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}

export default new Authentication();

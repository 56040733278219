import CRUD from "../utils/CRUD";

const getByPostDataController = (params) => {
  return new Promise((resolve, reject) => {
    CRUD.getByPost( params.data, params.url ).then((res) => {
      if (!res.isError) {
        resolve(res);
      } else {
        resolve(false);
      }
    })
    .catch((err) => {
      reject(err);
    });
  });
};

const getDataController = (params) => {
  return new Promise((resolve, reject) => {
    CRUD.get( params.data, params.url ).then((res) => {
      if (!res.isError) {
        resolve(res);
      } else {
        resolve(false);
      }
    })
    .catch((err) => {
      reject(err);
    });
  });
};

const storeDataController = (params) => {
  return new Promise((resolve, reject) => {
    CRUD.save( params.data, params.url ).then((res) => {
      if (!res.isError) {
        resolve(res);
      } else {
        resolve(false);
      }
    })
    .catch((err) => {
      reject(err);
    });
  });
};

const updateDataController = (params) => {
  return new Promise((resolve, reject) => {
    CRUD.update( params.data, params.url ).then((res) => {
      if (!res.isError) {
        resolve(res);
      } else {
        resolve(false);
      }
    })
    .catch((err) => {
      reject(err);
    });
  });
};

const deleteDataController = (params) => {
  return new Promise((resolve, reject) => {
    CRUD.delete( params.data, params.url ).then((res) => {
      if (!res.isError) {
        resolve(res);
      } else {
        resolve(false);
      }
    })
    .catch((err) => {
      reject(err);
    });
  });
};

const postDataController = (params) => {
  return new Promise((resolve, reject) => {
    CRUD.post( params.data, params.url ).then((res) => {
      if (!res.isError) {
        resolve(res);
      } else {
        resolve(false);
      }
    })
    .catch((err) => {
      reject(err);
    });
  });
}


export { getDataController, getByPostDataController, storeDataController, updateDataController, deleteDataController, postDataController };

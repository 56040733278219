import { combineReducers } from "redux";

import loggedReducer from "./logged";
import setupDropdownRecordsReducer from "./setup_dropdown_records";

const allReducers = combineReducers({
  isAuthenticated: loggedReducer.isAuthenticated,
  profile: loggedReducer.profile,
  isLoggingOut : loggedReducer.isLoggingOut,
  
  dropdownTables : setupDropdownRecordsReducer.dropdownTables,
  dropdownTableData : setupDropdownRecordsReducer.dropdownTableData,
  dropdownTableColumns : setupDropdownRecordsReducer.dropdownTableColumns,
  dropdownTablePagination : setupDropdownRecordsReducer.dropdownTablePagination,
});

export default allReducers;

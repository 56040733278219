import Request from "./Request";

class CRUD {
  constructor() {
    this.Request = new Request();
  }

  save( data, url ) {
    return new Promise((resolve, reject) => {
      const payload = {...data};
      this.Request.post(url, payload)
        .then((res) => {
          let result = res.data;
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  get( data, url ) {
    return new Promise((resolve, reject) => {

      const payload = {...data};

      this.Request.get(url, payload)
        .then((res) => {
          let result = res.data;
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  post( data, url ) {
    return new Promise((resolve, reject) => {

      const payload = {...data};

      this.Request.post(url, payload)
        .then((res) => {
          let result = res.data;
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getByPost( data, url ) {
    return new Promise((resolve, reject) => {

      const payload = {...data};

      this.Request.post(url, payload)
        .then((res) => {
          let result = res.data;
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  update( data, url ) {
    return new Promise((resolve, reject) => {

      const payload = {...data};

      this.Request.put(url, payload)
        .then((res) => {
          let result = res.data;
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  delete( data, url ) {
    return new Promise((resolve, reject) => {

      const payload = {...data};

      this.Request.delete(url, payload)
        .then((res) => {
          let result = res.data;
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}

export default new CRUD();

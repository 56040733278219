import Authentication from "../utils/Authentication";

const loginController = (credentials) => {
  return new Promise((resolve, reject) => {
    // Initiate login
    Authentication.login(credentials)
      .then((res) => {
        if (!res.isError) {
          resolve(res.response.user);
        } else {
          resolve(false);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const logoutController = () => {
  return new Promise((resolve, reject) => {
    // Initiate login
    Authentication.logout()
      .then((res) => {
        if (!res.isError) {
          resolve(res.response);
        } else {
          resolve(false);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const sessionCheckController = () => {
  return new Promise((resolve, reject) => {
    Authentication.checkSession()
      .then((res) => {
        if (!res.isError) {
          resolve(res.response.user);
        } else {
          resolve(false);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const updatePasswordController = (userDetails) => {
  return new Promise((resolve, reject) => {
    // Initiate login

    Authentication.updatePassword(userDetails)
      .then((res) => {
        if (!res.isError) {
          resolve(res.response);
        } else {
          resolve(false);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export { loginController, logoutController, sessionCheckController, updatePasswordController };

import { lazy } from "react";

const routes = [
  {
    path: "dashboard",
    component: lazy(() => import("./containers/dashboard/Dashboard")),
    exact: true,
  },
  {
    path: "my-account",
    component: lazy(() => import("./containers/account/Index")),
    exact: true,
  },
  {
    path: "inventory/property-offers",
    component: lazy(() => import("./containers/modules/Inventory")),
    exact: true,
  },
  {
    path: "inventory/inventory-list",
    component: lazy(() => import("./containers/modules/Inventory")),
    exact: true,
  },
  {
    path: "inventory/projects",
    component: lazy(() => import("./containers/modules/Inventory")),
    exact: true,
  },
  {
    path: "inventory/selling-price",
    component: lazy(() => import("./containers/modules/Inventory")),
    exact: true,
  },
  {
    path: "inventory/appraisal-value",
    component: lazy(() => import("./containers/modules/Inventory")),
    exact: true,
  },
  {
    path: "inventory/record-approvals",
    component: lazy(() => import("./containers/modules/Inventory")),
    exact: true,
  },
  {
    path: "inventory/change-item-status",
    component: lazy(() => import("./containers/modules/Inventory")),
    exact: true,
  },
  {
    path: "inventory/business-plan",
    component: lazy(() => import("./containers/modules/Inventory")),
    exact: true,
  },
  {
    path: "inventory/site-map",
    component: lazy(() => import("./containers/modules/Inventory")),
    exact: true,
  },
  {
    path: "setup/dropdown-records",
    component: lazy(() => import("./containers/setup/DropdownRecords")),
    exact: true,
  },
  {
    path: "inventory/inventory-setup/market-segment-matrix",
    component: lazy(() => import("./containers/modules/Inventory")),
    exact: true,
  },
  {
    path: "setup/record-approval-assignment",
    component: lazy(() => import("./containers/setup/RecordApprovalAssignment")),
    exact: true,
  },
  {
    path: "inventory/inventory-setup/inventory-checklist",
    component: lazy(() => import("./containers/modules/Inventory")),
    exact: true,
  },
  {
    path: "inventory/inventory-setup/price-factors",
    component: lazy(() => import("./containers/modules/Inventory")),
    exact: true,
  },
  {
    path: "inventory/inventory-setup/lot-price-parameters",
    component: lazy(() => import("./containers/modules/Inventory")),
    exact: true,
  },
  {
    path: "inventory/inventory-setup/stages-by-project-classification",
    component: lazy(() => import("./containers/modules/Inventory")),
    exact: true,
  },
  {
    path: "settings/user-accounts",
    component: lazy(() => import("./containers/settings/UserAccounts")),
    exact: true,
  },
  {
    path: "settings/user-roles",
    component: lazy(() => import("./containers/settings/UserRoles")),
    exact: true,
  },
  {
    path: "settings/user-role-access",
    component: lazy(() => import("./containers/settings/UserRoleAccess")),
    exact: true,
  },
  {
    path: "settings/designations",
    component: lazy(() => import("./containers/settings/Designation")),
    exact: true,
  },
  {
    path: "settings/departments",
    component: lazy(() => import("./containers/settings/Department")),
    exact: true,
  },
  {
    path: "sales/customer-information",
    component: lazy(() => import("./containers/modules/Sales")),
    exact: true,
  },
  {
    path: "sales/customer-information/form",
    component: lazy(() => import("./containers/modules/Sales")),
    exact: true,
  },
  {
    path: "sales/seller-information",
    component: lazy(() => import("./containers/modules/Sales")),
    exact: true,
  },
  {
    path: "sales/seller-information/form",
    component: lazy(() => import("./containers/modules/Sales")),
    exact: true,
  },
  {
    path: "sales/sample-computation",
    component: lazy(() => import("./containers/modules/Sales")),
    exact: true,
  },
  {
    path: "sales/reservation-entry",
    component: lazy(() => import("./containers/modules/Sales")),
    exact: true,
  },
  {
    path: "sales/credit-approval",
    component: lazy(() => import("./containers/modules/Sales")),
    exact: true,
  },
  {
    path: "sales/document-printing",
    component: lazy(() => import("./containers/modules/Sales")),
    exact: true,
  },
  {
    path: "sales/record-approvals",
    component: lazy(() => import("./containers/modules/Sales")),
    exact: true,
  },
  {
    path: "sales/sales-setup/reservation-requirements",
    component: lazy(() => import("./containers/modules/Sales")),
    exact: true,
  },
  {
    path: "sales/sales-setup/seller-requirements",
    component: lazy(() => import("./containers/modules/Sales")),
    exact: true,
  },
  {
    path: "sales/sales-setup/payment-terms",
    component: lazy(() => import("./containers/modules/Sales")),
    exact: true,
  },
  {
    path: "sales/sales-setup/promos-and-discounts",
    component: lazy(() => import("./containers/modules/Sales")),
    exact: true,
  },
  {
    path: "sales/sales-setup/disclaimer-statement",
    component: lazy(() => import("./containers/modules/Sales")),
    exact: true,
  },
  {
    path: "sales/sales-setup/mobile-app-announcements",
    component: lazy(() => import("./containers/modules/Sales")),
    exact: true,
  },
  {
    path: "sales/sales-setup/mobile-app-faq",
    component: lazy(() => import("./containers/modules/Sales")),
    exact: true,
  },
  {
    path: "sales/sales-setup/payment-term-discounts",
    component: lazy(() => import("./containers/modules/Sales")),
    exact: true,
  },
  {
    path: "sales/sales-report/price-list",
    component: lazy(() => import("./containers/modules/Sales")),
    exact: true,
  },
  {
    path: "accounting/employee-information",
    component: lazy(() => import("./containers/modules/Accounting")),
    exact: true,
  },
  {
    path: "accounting/supplier-information",
    component: lazy(() => import("./containers/modules/Accounting")),
    exact: true,
  },
  {
    path: "accounting/item-master",
    component: lazy(() => import("./containers/modules/Accounting")),
    exact: true,
  },
  {
    path: "accounting/item-purchase",
    component: lazy(() => import("./containers/modules/Accounting")),
    exact: true,
  },
  {
    path: "accounting/reservation-payment-entry",
    component: lazy(() => import("./containers/modules/Accounting")),
    exact: true,
  },
  {
    path: "accounting/record-approvals",
    component: lazy(() => import("./containers/modules/Accounting")),
    exact: true,
  },
  {
    path: "collection/cash-payments",
    component: lazy(() => import("./containers/modules/Collection")),
    exact: true,
  },
  {
    path: "collection/check-acceptances",
    component: lazy(() => import("./containers/modules/Collection")),
    exact: true,
  },
  {
    path: "collection/check-taggings",
    component: lazy(() => import("./containers/modules/Collection")),
    exact: true,
  },
  {
    path: "collection/record-approvals",
    component: lazy(() => import("./containers/modules/Collection")),
    exact: true,
  },
  {
    path: "collection/collection-setup/bank-accounts",
    component: lazy(() => import("./containers/modules/Collection")),
    exact: true,
  },
  {
    path: "collection/collection-setup/check-statuses",
    component: lazy(() => import("./containers/modules/Collection")),
    exact: true,
  },
  {
    path: "support/chat",
    component: lazy(() => import("./containers/support/Chat/Chat")),
    exact: true,
  },
];

export default routes;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import { Popover } from "antd";
import { Link, Navigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faTrash } from "@fortawesome/free-solid-svg-icons";

const UL = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;
const LI = styled.li``;
const Item = styled.div`
  display: flex;
  padding: 12px 18px;
  padding-left: ${props => `${props.depth * 18}px`};
  align-items: center;
`;
const Label = styled.span`
  width: 100%;
  display: block;
  cursor: pointer;
`;
const Arrow = styled.span`
  display: flex;
  height: 25px;
  width: 35px;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &::after {
    content: "";
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;

    border-top: 4px solid #000;

    transform: ${props => (props.toggle ? "rotate(180deg)" : "rotate(0deg)")};
  }
`;



const MultiMenus = ({ userPermissions, menus, t, isFavoritesDisplayed, saveFavoriteLink, deleteFavoriteLink, isCollapsed, setCollapse }) => {

  const navigate = useNavigate()
  const [activeMenus, setActiveMenus] = useState([...menus]);

  useEffect(() => {
    setActiveMenus([...menus])
  }, [menus])

  const handleClickLink = (data, parentKey, hasChildren) => {
   
    if(data.key==="dashboard"){
      navigate(`/${data.key}`)
      return
    }

    if(!hasChildren && parentKey!==null){
      navigate(data.module===parentKey?
        `/${parentKey}/${data.key}`:
        `/${data.module}/${parentKey}/${data.key}`)
      return
    }

    if(!hasChildren && parentKey===null){
      navigate(`/${data.key}`)
      return
    }

  };

  const handleArrowClick = (data, key) => {

    var newActiveMenus = [...activeMenus]
    let parentModule = data.module!==undefined && data.module!==null ? data.module : null

    if(isCollapsed){
      setCollapse(false)
    }

    if(parentModule!==null){
      let parentGroupIndex = newActiveMenus.findIndex( a => a.key === parentModule )
      let parentSubGroupIndex = newActiveMenus[parentGroupIndex].children.findIndex( p => p.key === data.key )

      newActiveMenus[parentGroupIndex].children.map( (a,index) => {
        if(a.children){
          if(parentSubGroupIndex==index){
            a.toggle_children = !a.toggle_children
            
          }else{
            a.toggle_children = false
          }
        }
      })

      setActiveMenus([...newActiveMenus])

    }else{
      newActiveMenus.map( a => {
        if(a.children){
          if(a.key === data.key){
            a.toggle_children = !a.toggle_children
          }else{
            a.toggle_children = false
          }
        }
      })

      setActiveMenus([...newActiveMenus])

    }

  }

  const handleLinkClick = (data, key) => {

    var newActiveMenus = [...activeMenus]
    let parentModule = data.module!==undefined && data.module!==null ? data.module : null
    
    if(isCollapsed){
      setCollapse(false)
    }

    if(parentModule!==null){
      let parentGroupIndex = newActiveMenus.findIndex( a => a.key === parentModule )
      let parentSubGroupIndex = newActiveMenus[parentGroupIndex].children.findIndex( p => p.key === data.key )
      newActiveMenus[parentGroupIndex].children.map( (a,index) => {
        if(a.children){
          if(parentSubGroupIndex==index){
            a.toggle_children = !a.toggle_children
          }else{
            a.toggle_children = false
          }
        }
      })

      setActiveMenus([...newActiveMenus])

    }else{
      
      newActiveMenus.map( a => {
        if(a.children){
          if(a.key === data.key){
            a.toggle_children = !a.toggle_children
           
          }else{
            a.toggle_children = false
          }
        }
      })

      setActiveMenus([...newActiveMenus])

    }

  }

  const ListMenu = ({ depth, data, parentKey, hasChildren, menuName, menuIndex }) => (
    <LI>
      {
        data.key !== "dashboard" ? 
          ( userPermissions!==undefined && userPermissions.find( u => u.module_code === data.key ) !== undefined && userPermissions.find( u => u.module_code === data.key ).permissions.length==0 ) ?
          ""
          :
            <Item depth={depth}>
              {
                hasChildren &&
                <Label onClick={() => handleLinkClick(data, parentKey)}>{!isCollapsed?t(`sidebar.${data.label}`):data.icon}</Label>
              }
              {
                (!hasChildren && parentKey!==null) &&
                <Popover placement="right" content={ !isFavoritesDisplayed? <a onClick={()=>saveFavoriteLink(data)}><FontAwesomeIcon icon={faStar} className="mr-2"/>{t('sidebar.make_favorite')}</a> : <a onClick={()=>deleteFavoriteLink(data)}><FontAwesomeIcon icon={faTrash} className="mr-2"/>{t('sidebar.remove_favorite')}</a> } title="" trigger="hover">
                  <p onClick={() => handleClickLink(data, parentKey, hasChildren)} className="side-link">{t(`sidebar.${data.label}`)}</p>
                </Popover>
              }
              {
                (!hasChildren && parentKey===null) &&
                <Popover placement="right" content={ !isFavoritesDisplayed? <a onClick={()=>saveFavoriteLink(data)}><FontAwesomeIcon icon={faStar} className="mr-2"/>{t('sidebar.make_favorite')}</a> : <a onClick={()=>deleteFavoriteLink(data)}><FontAwesomeIcon icon={faTrash} className="mr-2"/>{t('sidebar.remove_favorite')}</a> } title="" trigger="hover">
                  <p onClick={() => handleClickLink(data)} className="side-link">{t(`sidebar.${data.label}`)}</p>
                </Popover>
              }
              {hasChildren && (
                <Arrow
                  onClick={() => handleArrowClick(data, parentKey)}
                  toggle={data.toggle_children}
                />
              )}
            </Item>
        :
        <Item depth={depth}>
          <Popover placement="right" content={ !isFavoritesDisplayed? <a onClick={()=>saveFavoriteLink(data)}><FontAwesomeIcon icon={faStar} className="mr-2"/>{t('sidebar.make_favorite')}</a> : <a onClick={()=>deleteFavoriteLink(data)}><FontAwesomeIcon icon={faTrash} className="mr-2"/>{t('sidebar.remove_favorite')}</a> } title="" trigger="hover">
            <p onClick={() => handleClickLink(data, "dashboard", false)} className="side-link">{!isCollapsed?t(`sidebar.${data.label}`):data.icon}</p>
          </Popover>
        </Item>
      }
      {hasChildren && (
        <SubMenu
          depth={depth}
          data={data.children}
          onClick={() => handleArrowClick(data)}
          toggle={data.toggle_children}
          menuIndex={menuIndex}
          parentKey={parentKey}
        />
      )}
    </LI>
  );

  const SubMenu = ({ depth, data, toggle, menuIndex, parentKey }) => {
    if (!toggle) {
      return null;
    }

    depth = depth + 1;

    return (
      <UL>
        {data.map((menu, index) => {
          const menuName = `sidebar-submenu-${depth}-${menuIndex}-${index}`;

          return (
            <ListMenu
              depth={depth}
              data={menu}
              hasChildren={menu.children}
              menuName={menuName}
              key={menuName}
              menuIndex={index}
              parentKey={menu.children?menu.key:parentKey}
            />
          );
        })}
      </UL>
    );
  };

  useEffect( () => {

    if(isCollapsed){
      var newActiveMenus = [...activeMenus]

      newActiveMenus.map( a => {
        if(a.children){
          a.toggle_children = false
        }
      })

      setActiveMenus([...newActiveMenus])
    }

  }, [isCollapsed])

  return (
    <UL>
      {menus.map((menu, index) => {
        const depth = 1;
        const menuName = `sidebar-menu-${depth}-${index}`;

        return (
          <ListMenu
            depth={depth}
            data={menu}
            parentKey={menu.children?menu.key:null}
            hasChildren={menu.children}
            menuName={menuName}
            key={menuName}
            menuIndex={index}
            userPermissions={userPermissions}
          />
        );
      })}
    </UL>
  );
};

export default MultiMenus;

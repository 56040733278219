import axios from "axios";

let instance = null;

class Request {
  constructor() {
    // Check if class has already been instantiated
    if (instance) {
      return instance;
    }

    // Get the CSRF token on instantiation
    this._getCSRFToken().catch((err) => {
      console.log(err);
    });

    // Instantiate class only once
    instance = this;
  }

  _getCSRFToken() {
    return new Promise((resolve, reject) => {
      // Create the config object for the axios call
      let config = {
        url: "/csrf",
        method: "get",
        headers: { "X-CSRF-Token": "Fetch" },
      };

      // Get CSRF token for use in POST requests
      this._axiosExecute(config)
        .then((res) => {
          resolve();
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }

  _axiosExecute(config) {
    return new Promise((resolve, reject) => {
      // Additional global config for axios
      config.xsrfCookieName = "X-CSRF-Token";
      config.xsrfHeaderName = "X-CSRF-Token";

      // Execute the axios call to backend
      axios(config)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }

  get(url, payload) {
    return new Promise((resolve, reject) => {
      // Create the config object for the axios call
      let config = {
        url: url,
        method: "get",
        headers: {},
        data: payload,
      };
      // Execute axios call to backend
      this._axiosExecute(config)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  post(url, payload) {
    return new Promise((resolve, reject) => {
      // Create the config object for the axios call
      let config = {
        url: url,
        method: "post",
        headers: {},
        data: payload,
      };
      // Execute axios call to backend
      this._axiosExecute(config)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  put(url, payload) {
    return new Promise((resolve, reject) => {
      // Create the config object for the axios call
      let config = {
        url: url,
        method: "put",
        headers: {},
        data: payload,
      };

      // Execute axios call to backend
      this._axiosExecute(config)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  delete(url, payload) {
    return new Promise((resolve, reject) => {
      // Create the config object for the axios call
      let config = {
        url: url,
        method: "delete",
        headers: {},
        data: payload,
      };

      // Execute axios call to backend
      this._axiosExecute(config)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}

export default Request;

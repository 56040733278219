import React from "react";
import { Layout } from "antd";

import LoginForm from "../../containers/login/LoginForm";

const { Content } = Layout;

function Login() {
  return (
    <>
      <Layout>
        <Layout>
          {/* Main content containing the log in form */}
          <Content className="grid place-items-center h-screen login-bg">
            <LoginForm />
          </Content>
        </Layout>
      </Layout>
    </>
  );
}

export default Login;

const setupDropdownRecordsReducer = {
  dropdownTables: (state = [], action) => {
    switch (action.type) {
      case "SET_DROPDOWN_RECORD_TABLES":
        return action.payload
      default:
        return state;
    }
  },
  dropdownTableData: (state = [], action) => {
    switch (action.type) {
      case "SET_DROPDOWN_TABLE_DATA":
        return action.payload
      default:
        return state;
    }
  },
  dropdownTableColumns: (state = [], action) => {
    switch (action.type) {
      case "SET_DROPDOWN_TABLE_COLUMNS":
        return action.payload
      default:
        return state;
    }
  },
  dropdownTablePagination: (state = [], action) => {
    switch (action.type) {
      case "SET_DROPDOWN_TABLE_PAGINATION":
        return action.payload
      default:
        return state;
    }
  },
};

export default setupDropdownRecordsReducer;

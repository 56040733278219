import { lazy, Suspense, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { LoadingOutlined } from "@ant-design/icons";

import Login from "./containers/login/Login";
import PrivateRoute from "./components/routes/PrivateRoute";
import PublicRoute from "./components/routes/PublicRoute";
import routes from "./routes";

import {
  sessionCheckController,
} from "./controllers/authentication.controller";

import { logged } from "./redux/actions";
import Request from "./utils/Request";
import Unauthorized from "./containers/401/Unauthorized";
import { Spin } from "antd";

const App = () => {
  // Instantiate useDispatch
  const dispatch = useDispatch();

  const isAuthenticated = useSelector((state) => state.isAuthenticated);
  const isLoggingOut = useSelector((state) => state.isLoggingOut);

  useEffect(() => {
    new Request();

    // Check if the user has a valid session
    sessionCheckController()
      .then((res) => {
        // Get user profile
        // This will be changed to get real user jobTitle
        let userProfile = {
          id: res.id,
          name: res.fullName,
          role: res.role,
          permissions: res.permissions,
        };

        // Update user profile
        dispatch(logged.profile(userProfile));

        // Change isAuthenticated to true
        dispatch(logged.login());
      })
      .catch((err) => {
        // Remove user profile
        dispatch(logged.profile({}));
        // Change isAuthenticated to false
        dispatch(logged.logout());
      });
  });

  return (
    <Spin spinning={isLoggingOut} size="large" tip="Logging out">
      <Router>
        <Suspense fallback={<LoadingOutlined />}>
          <Routes>
            {/* Public Routes */}
            {
              <Route
                path="/"
                element={
                  <PublicRoute path="/" isAuthenticated={isAuthenticated}>
                    {/* Login route */}
                    <Login />
                  </PublicRoute>
                }
              />
            }
            {routes.map(({ component: Component, path, exact }) => (
              <Route
                path={`/${path}`}
                exact={exact}
                element={
                  <PrivateRoute
                    path={`/${path}`}
                    isAuthenticated={isAuthenticated}
                    isLoggingOut={isLoggingOut}
                  >
                    <Component />
                  </PrivateRoute>
                }
              />
            ))}
            
            <Route path="/401" exact={true} element={<Unauthorized/>} />
          </Routes>
        </Suspense>
      </Router>
    </Spin>

  );
};

export default App;

const loggedReducer = {
  isAuthenticated: (state = false, action) => {
    switch (action.type) {
      case "LOG_IN":
        return true;
      case "LOG_OUT":
        // Remove the user details from the session storage
        sessionStorage.removeItem("5r_user");
        return false;
      default:
        return state;
    }
  },
  profile: (state = { id: "", name: "", role: "", permissions: [] }, action) => {
    switch (action.type) {
      case "SET_PROFILE":
        // Set the user details in the session storage
        sessionStorage.setItem("5r_user", JSON.stringify(action.payload));
        return action.payload;
      default:
        return state;
    }
  },
  isLoggingOut : (state = false, action) => {
    switch (action.type) {
      case "LOGGING_OUT":
        return action.payload
      default:
        return state;
    }
  }
};

export default loggedReducer;

import React, { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import sidebar_options from "../../config/sidebar_options";

import DefaultUser from "../../images/default-user.png";
import { storeDataController, postDataController, getByPostDataController } from "../../controllers/crud.controller";
import { logoutController } from "../../controllers/authentication.controller";
import { logged } from "../../redux/actions";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHamburger,
  faStar,
  faBell,
  faAlignJustify,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

import { Layout, Menu, Image, Input, Popover } from "antd";
import MultiMenus from "./MultiMenu";
const { SubMenu } = Menu;
const { Sider } = Layout;

function Sidebar(props) {
  // Hook for translation
  const { t } = useTranslation();

  let navigate = useNavigate();

  const dispatch = useDispatch();

  // Get user profile
  const user = useSelector((state) => state.profile);
  const isLoggingOut = useSelector((state) => state.isLoggingOut);

  const [sidebarOptions, setSidebarOptions] = useState([])

  const [isCollapsed, setCollapse] = useState(false);
  const [currentKey, setKey] = useState("dashboard");

  const [filterText, setFilterText] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);

  const sidebarHeaderStyle = {
    display: isCollapsed ? "block" : "flex",
    textAlign: !isCollapsed ? "center" : "left",
    marginLeft : !isCollapsed ? "" : "10px"
  };

  const filterOptions = (filter) => {
    let filtered = [];

    sidebar_options.map((s) => {
      if (s.label.toLowerCase().indexOf(filter.toLowerCase()) !== -1) {
        filtered.push(s);
      }

      if (s.children) {
        s.children.map((sc) => {
          if (sc.label.toLowerCase().indexOf(filter.toLowerCase()) !== -1) {
            let tempKey = sc.key.replace(`${s.key}/`,"")
            sc.key = s.key +"/"+tempKey
            filtered.push(sc);
          }
        });
      }
    });

    setFilterText(filter);
    setFilteredOptions(filtered);

    if (filter.trim() === "") {
      sidebar_options.map((s) => {
        if (s.children) {
          s.children.map((sc) => {
            sc.key = sc.key.replace(`${s.key}/`,"")
          });
        }
      })
      setFilterText("");
      setFilteredOptions([])
    }
  }

  const [isFavoritesDisplayed, setFavoritesDisplayed] = useState(false)

  const toggleFavorites = () => {
    
    if(isFavoritesDisplayed){
      setFilteredOptions([])
      setFavoritesDisplayed(false)
    }else{
      let filtered = [];

      favoriteLinks.map( f => {
        sidebar_options.map((s) => {
          if (s.label.toLowerCase().indexOf(f.label.toLowerCase()) !== -1) {
            filtered.push(s);
          }
    
          if (s.children) {
            s.children.map((sc) => {
              if (sc.label.toLowerCase().indexOf(f.label.toLowerCase()) !== -1) {
                sc.key = s.key +"/"+ f.label_key
                filtered.push(sc);
              }
            });
          }
        });
      })

      setFavoritesDisplayed(true)
      setFilteredOptions(filtered)

    }

  }

  useEffect( () => {
    getFavoriteLinks( user.id!==undefined ? user.id : "7278610b-957b-43d9-9254-34bfb57a36a3" )
  }, [] )

  const getFavoriteLinks = (id) => {
    getByPostDataController({
      data : {
        filter : [
          {
            field : "fl.created_by",
            type : "string",
            operation : "=",
            value : id
          }
        ],
      },
      url : `/api/v1/favorite-links/fetch`
    })
      .then((res) => {

        if (res) {
          setFavoriteLinks(res.items)
        } else {
          // Set the errors
        }
      })
      .catch((err) => {
        // Set the errors
        console.log(err)
      })
  }

  const [favoriteLinks, setFavoriteLinks] = useState([])

  const saveFavoriteLink = (menu) => {

    let favoriteLinkIndex = favoriteLinks.findIndex( f => f.label === menu.label )

    if(favoriteLinkIndex==-1){
      storeDataController({
        data : {
          created_by : user.id!==undefined ? user.id : "7278610b-957b-43d9-9254-34bfb57a36a3",
          label_key : menu.key,
          label : menu.label
        },
        url : `/api/v1/favorite-links/store`
      })
        .then((res) => {
          if (res) {
            if(!res.isError){
              getFavoriteLinks( user.id!==undefined ? user.id : "7278610b-957b-43d9-9254-34bfb57a36a3" )
            }
          } else {
          
          }
        })
        .catch((err) => {
          // Set the errors
          console.log(err)
        })
    }
  }

  const deleteFavoriteLink = (menu) => {

    let favoriteLinkIndex = favoriteLinks.findIndex( f => f.label === menu.label )

    if(favoriteLinkIndex!=-1){
      postDataController({
        data : {
          created_by : user.id!==undefined ? user.id : "7278610b-957b-43d9-9254-34bfb57a36a3",
          label : menu.label,
        },
        url : `/api/v1/favorite-links/delete`
      })
        .then((res) => {
          if (res) {
            if(!res.isError){
              getFavoriteLinks( user.id!==undefined ? user.id : "7278610b-957b-43d9-9254-34bfb57a36a3" )
            }
          } else {
          
          }
        })
        .catch((err) => {
          // Set the errors
          console.log(err)
        })
    }
  }

  const [isAccountPopoverVisible, setIsAccountPopoverVisible] = useState(false)
  const popoverContent = () => {

    return <div>
      <p style={{ marginBottom : "0", cursor : "pointer" }} onClick={()=>{setIsAccountPopoverVisible(false);navigate('/my-account')}}>
        {t('settings.user.my_account')}
      </p>
      <p style={{ marginBottom : "0", cursor : "pointer" }} onClick={logout}>
        {t('settings.user.logout')}
        </p>
    </div>

  }

  const logout = () => {

    dispatch(logged.isLoggingOut(true))
    logoutController()
      .then((res) => {
        if (res) {
          console.log(res)
          if(res.statusCode==200){
            // Remove user profile
            dispatch(logged.profile({}));
            // Change isAuthenticated to false
            dispatch(logged.logout());
            dispatch(logged.isLoggingOut(false))
          }
        } else {
          dispatch(logged.isLoggingOut(false))
        }
      })
      .catch((err) => {
        dispatch(logged.isLoggingOut(false))
      });
  }

  const checkSubLevel = (depth, data, index) => {
    
    depth = depth + 1;

    data.map( (d, dIndex) => {

      return checkTopLevel(
        depth,
        d,
        d.children,
        dIndex
      )

    })

  }

  const checkTopLevel = (depth, data, index) => {

    if(data.children){

      data.children.map( (d, dIndex) => {

        checkSubLevel(
          depth,
          d,
          d.children,
          dIndex
        )
  
      })
    }

  }

  useEffect( () => {

    if( user !== undefined && user!== null ) {
      let newSidebarOpts = [...sidebar_options]
      setSidebarOptions( newSidebarOpts )

    }

  }, [ user ] )

  useEffect( () => {
    setFilterText("");
    setFilteredOptions([])
  }, [isCollapsed])

  return (
    <Sider
      style={{ paddingTop: "10px", display: "flex", visibility: "visible" }}
      width={240}
      trigger={null}
      collapsible
      collapsed={isCollapsed}
    >
      <div className="sidebar-header" style={sidebarHeaderStyle} >
        {!isCollapsed && (
          <Popover
            title=""
            tigger="click"
            visible={isLoggingOut?false:isAccountPopoverVisible}
            content={popoverContent}
            placement="right"
          >
            <div className="header-image" onClick={()=>setIsAccountPopoverVisible(!isAccountPopoverVisible)} style={{ cursor : "pointer" }}>
              <img src={DefaultUser} />
            </div>
          </Popover>
        )}
        {!isCollapsed && (
          <div className="header-details">
            <p className="user-name">{user.name}</p>
            <span className="job-title">{user.role}</span>
          </div>
        )}
        <div className="header-actions">
          <FontAwesomeIcon
            onClick={() => setCollapse(!isCollapsed)}
            icon={faAlignJustify}
          />
          <FontAwesomeIcon className={isFavoritesDisplayed?"text-blue-900":""} onClick={toggleFavorites} icon={faStar} />
          <FontAwesomeIcon icon={faBell} />
        </div>
      </div>
      {!isCollapsed && (
        <div className="sidebar-search">
          <Input
            className="rounded-2xl"
            placeholder="Search..."
            onKeyUp={(e) => {
              filterOptions(e.target.value);
            }}
            allowClear={true}
          />
        </div>
      )}
      <MultiMenus 
        userPermissions={user.permissions} 
        menus={filteredOptions.length!==0? filteredOptions : sidebarOptions} 
        t={t} 
        isFavoritesDisplayed={isFavoritesDisplayed} 
        saveFavoriteLink={saveFavoriteLink} 
        deleteFavoriteLink={deleteFavoriteLink} 
        isCollapsed={isCollapsed}
        setCollapse={setCollapse}
      />
    </Sider>
  );
}

export default Sidebar;

import { Layout, Typography } from "antd"
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const { Content } = Layout
const { Title } = Typography

function Unauthorized () {

  const { t } = useTranslation();

  return <>
  <Layout>
    <Layout>
      <Content className="p-4">
        <Title>{t("global.unauthorized_module_message")}</Title>
      </Content>
    </Layout>
  </Layout>
</>
}

export default Unauthorized
import { Navigate } from "react-router-dom";

function PublicRoute({ path, isAuthenticated, children }) {
  return !isAuthenticated ? (
    children
  ) : (
    <Navigate
      to={{
        pathname: "/dashboard",
        state: { from: path },
      }}
    />
  );
}

export default PublicRoute;

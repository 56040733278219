import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Form,
  Input,
  Button,
  Checkbox,
  Image,
  Typography,
  Spin,
  message,
  Alert,
} from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";

import { loginController } from "../../controllers/authentication.controller";

import { logged } from "../../redux/actions";
const { Title, Text } = Typography;

function LoginForm(props) {
  // Hook for translation
  const { t } = useTranslation();

  // Instantiate useDispatch
  const dispatch = useDispatch();

  // Initialize local states
  const [isLoggingIn, setLoggingIn] = useState(false);
  const [alertMessage, setAlertMessage] = useState({ show: false, msg: "" });
  const [isInvalidEmail, setInvalidEmail] = useState(false);
  const [isInvalidPassword, setInvalidPassword] = useState(false);

  // Set function for setting states to error
  const setError = ( errorCode = 423 ) => {
    // Enable the login form again to allow retry to log in
    setLoggingIn(false);

    // Set inputs to invalid
    setInvalidEmail(true);
    setInvalidPassword(true);

    // Set alert message for incorrect login
    setAlertMessage({ show: true, msg: errorCode!==423? t("login.login_alert") : t("login.login_account_locked") });
    message.error(errorCode!==423? t("login.login_alert") : t("login.login_account_locked"));
  };

  // Set function to be triggered on form submit
  const onFinish = (credentials) => {
    // Disable the login form while logging in to backend
    setLoggingIn(true);

    // Call the login controller to initiate log in process
    loginController(credentials)
      .then((res) => {
        if (res) {
          // Set inputs to valid
          setInvalidEmail(false);
          setInvalidPassword(false);

          // Remove alert message
          setAlertMessage({ show: false, msg: "" });

          // Get user profile
          // This will be changed to get real user jobTitle
          let userProfile = {
            id: res.id,
            name: res.fullName,
            id : res.id,
            role : res.role,
            permissions: res.permissions,
          };

          // Update user profile
          dispatch(logged.profile(userProfile));

          // Show a login success message
          // Change isAuthenticated to true after message
          message
            .success({
              content: t("login.login_successful"),
            })
            .then(() => dispatch(logged.login()));
        } else {
          console.log(res)
          // Set the errors
          setError();
        }
      })
      .catch((err) => {
        if(err.response!==undefined){
          if(err.response.status!==undefined){
            setError(err.response.status)
          }
        }
        // Set the errors
      });
  };

  return (
    <div className="w-4/6 md:w-auto grid grid-cols-2 bg-white login-center-div rounded-xl">
      {/* div containing the logo and slogan */}
      <div className="h-full w-full px-5 py-10 flex flex-col items-center justify-center rounded-l-xl login-logo-bg">
        <div className="w-4/5 flex flex-col items-center justify-center">
          <Image
            src="/5R_Logo.png"
            preview={false}
            className="mb-5 w-40 lg:w-full xl:w-64"
          />
          <Text className="w-5/6 cursive-font text-xl xl:text-5xl text-center font-medium">
            {t("global.slogan")}
          </Text>
        </div>
      </div>
      <div className="px-5 py-10 flex flex-col items-center justify-center">
        <Spin spinning={isLoggingIn} wrapperClassName="w-full">
          {/* div containing the login form */}
          <div className="flex flex-col items-center">
            <Title level={2} className="p-5 text-gray-800">
              {t("login.log_in")}
            </Title>
            {/* Login form */}
            <Form
              name="normal_login"
              layout="vertical"
              className="login-form w-4/6"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
            >
              {alertMessage.show ? (
                <Alert
                  message={alertMessage.msg}
                  type="error"
                  showIcon
                  className="w-full mb-5"
                />
              ) : null}
              {/* Email input */}
              <Form.Item
                name="email"
                label={t("login.email")}
                validateStatus={isInvalidEmail ? "error" : null}
                rules={[
                  {
                    type: "email",
                    message: t("login.invalid_email"),
                  },
                  {
                    required: true,
                    message: t("login.missing_email"),
                  },
                ]}
              >
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder={t("login.enter_email")}
                  className="rounded-full"
                />
              </Form.Item>
              {/* Password input */}
              <Form.Item
                name="password"
                label={t("login.password")}
                validateStatus={isInvalidPassword ? "error" : null}
                rules={[
                  {
                    required: true,
                    message: t("login.missing_password"),
                  },
                ]}
              >
                <Input
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder={t("login.enter_password")}
                  className="rounded-full"
                />
              </Form.Item>
              {/* Remember me setting */}
              <Form.Item>
                <Form.Item name="remember" valuePropName="checked">
                  <Checkbox className="text-gray-800">
                    {t("login.remember_me")}
                  </Checkbox>
                </Form.Item>
              </Form.Item>
              {/* Login button */}
              <Form.Item>
                <Button type="primary" htmlType="submit" className="w-full">
                  {t("login.log_in")}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Spin>
      </div>
    </div>
  );
}

export default LoginForm;
